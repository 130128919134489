import { API, Auth, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import * as APIt from 'src/API';
import {
  getEmployeeActiveBadges,
  getEmployeeDetailsForLogin,
  getLookupTypeValueForTypeNameAndValue,
  listAccessLevelsForSite,
  listApproverGroups,
  listDuplicateVisitorAssets,
  listLookupTypes,
  listLookupTypeValuesForTypeName,
  listVisitorAccessLevels,
  listVisitorAccessLevelsForApprover,
  listVisitorAccessLevelsForVisitor,
  listSites,
  listVisitorAssets,
  listVisitorAssetsForApproverV2,
  listVisitorRequestsForSite,
  listVisitorAssetsForStatus,
  listApproverGroupMembers,
  listAccessLevelApproverGroups,
  listEmployees,
  listSupervisorReports,
  listVisitorAssessmentsByUniqueVisitorId,
  listVisitorAccessLevelsByUniqueVisitorId,
  listUnescortedVisitorRequestsForSite,
  listEscortedVisitorRequestsForSite,
} from 'src/graphql/queries';
import {
  cardholder as cardholderMutation,
  createRequestEscort as createRequestEscortMutation,
  deleteRequestEscort as deleteRequestEscortMutation,
  createUserAction as createUserActionMutation,
  createVisitorAccessLevel as createVisitorAccessLevelMutation,
  createVisitorAccessLevelApproval as createVisitorAccessLevelApprovalMutation,
  updateVisitor as updateVisitorMutation,
} from 'src/graphql/mutations';
import {
  getLookupTypeValue,
  getLookupTypeValuesForTypeName,
} from 'src/graphql/queries';
import * as uuid from 'uuid';
import { authenticatedUsername } from './AuthenticateUser';
import { ApprovalStatus, listEscortedVisitorRequestsForSiteCustom, listUnescortedVisitorRequestsForSiteCustom, LookupTypes, SIGInfraAPIv2Methods, VisitorRequestStatus, VisitorTypes } from 'src/constants/Constants';
import subHours from 'date-fns/subHours';
import isAfter from 'date-fns/isAfter';
import { ISelectOption } from 'src/interfaces';
import { debug } from 'src/utils/commonUtils';
import { SignatureV4 } from "@aws-sdk/signature-v4";
import { Sha256 } from "@aws-crypto/sha256-js";
import { HttpRequest } from "@aws-sdk/protocol-http";

export const ttl = (years: number): number => {
  const ttlDate = new Date();
  ttlDate.setFullYear(ttlDate.getFullYear()+years);
  const ttlEpochInt: number = Math.round(ttlDate.getTime()/1000);
  return ttlEpochInt;
}

export const auditDecorator = function(actionName: string, fn: Function) {
  debug(`auditorDecorator() actionName is ${actionName}`);
  return function(...args: any[]) {
    debug(`auditorDecorator() actionName is ${actionName}`);
    debug(`auditorDecorator() args is ${args}`);
    const userActionInput: APIt.UserActionInput = {
      actionId: uuid.v4(),
      actionName: actionName,
      parameters: JSON.stringify(args),
      timestamp: Date.now().toString(),
      ttl: ttl(1),
      username: authenticatedUsername,
    };
    createUserAction(userActionInput);
    return fn(...args);
  }
};

export let createCardholder = async (cardholderRequestInput: APIt.CardholderRequestInput, updateVisitorInput: APIt.UpdateVisitorInput, requested_by: string): Promise<APIt.CardholderRequestResponse | null> => {
  debug(`createCardholder() cardholderRequestInput is ${JSON.stringify(cardholderRequestInput)} updateVisitorInput is ${updateVisitorInput} requested_by is ${requested_by}`);

  let cardholderRequestResponse: APIt.CardholderRequestResponse | null = null;

  try {
    let updatedVisitor;
    const cardholderMutationResponse = await API.graphql(graphqlOperation(cardholderMutation,
      {
        input: cardholderRequestInput
      })) as GraphQLResult<APIt.CardholderMutation>;
    debug(`createCardholder() cardholderMutationResponse is ${JSON.stringify(cardholderMutationResponse)}`);
    if (cardholderMutationResponse.data && cardholderMutationResponse.data.cardholder) {
      cardholderRequestResponse = cardholderMutationResponse.data.cardholder as APIt.CardholderRequestResponse;
      if (cardholderRequestResponse.statusCode !== 202) throw new Error('Failure to create cardholder.');
      updateVisitorInput = {
        ...updateVisitorInput,
        requestUUID: cardholderRequestResponse.requestUUID,
        updated_by: requested_by,
      };
      debug(`createCardholder() updateVisitorInput is ${JSON.stringify(updateVisitorInput)}`);
      const response = await API.graphql(graphqlOperation(updateVisitorMutation,
        {
          input: updateVisitorInput
        })) as GraphQLResult<APIt.UpdateVisitorMutation>;
      if (response && response.data && response.data.updateVisitor) {
        updatedVisitor = response.data.updateVisitor;
      }
      if (!updatedVisitor) throw new Error('Unable to update visitor.');
    }
  } catch(error) {
    debug(`createCardholder() exception is ${JSON.stringify(error)}`);
    console.error(error);
    throw error;
  }

  debug(`createCardholder() cardholderRequestResponse is ${JSON.stringify(cardholderRequestResponse)}`);
  return cardholderRequestResponse;
};
createCardholder = auditDecorator('createCardholder', createCardholder);

export const createVisitorAccessLevel = async (visitorAccessLevelInput: APIt.CreateVisitorAccessLevelInput): Promise<APIt.VisitorAccessLevel | null> => {
  debug(`createVisitorAccessLevel() visitorAccessLevelInput is ${JSON.stringify(visitorAccessLevelInput)}`);

  let createdVisitorAccessLevel: APIt.VisitorAccessLevel | null = null;

  try {
    if (visitorAccessLevelInput.reason) visitorAccessLevelInput.reason = sqlEscapeString(visitorAccessLevelInput.reason);
    const response = await API.graphql(graphqlOperation(createVisitorAccessLevelMutation,
      {
        input: visitorAccessLevelInput
      })) as GraphQLResult<APIt.CreateVisitorAccessLevelMutation>;
    if (response && response.data && response.data.createVisitorAccessLevel) {
      createdVisitorAccessLevel = response.data.createVisitorAccessLevel;
    }
  } catch(error) {
    console.error(`createVisitorAccessLevel() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return createdVisitorAccessLevel;
};

export const getAccessLevelApproverGroups = async (accessLevelName: string): Promise<string[]> => {
  debug(`getAccessLevelApproverGroups() accessLevelName is ${accessLevelName}`);

  let approverGroups: string[] = [];

  try {
    const response = await API.graphql(graphqlOperation(listAccessLevelApproverGroups,
      {
        accessLevelName: accessLevelName
      })) as GraphQLResult<APIt.ListAccessLevelApproverGroupsQuery>;
    if (response && response.data && response.data.listAccessLevelApproverGroups) {
      for (let alag of response.data.listAccessLevelApproverGroups) {
        if (alag?.approver_group) approverGroups.push(alag.approver_group);
      };
    }
  } catch(error) {
    console.error(`getAccessLevelApproverGroups() error is ${JSON.stringify(error)}`);
    throw error;
  }

  debug(`getAccessLevelApproverGroups() approverGroups is ${JSON.stringify(approverGroups)}`);
  return approverGroups;

};

export interface IQueryDuplicateVisitorAssetsParams {
  serialNum: string;
  type: string;
  username: string;
}

export let queryDuplicateVisitorAssets = async (params: IQueryDuplicateVisitorAssetsParams): Promise<APIt.VisitorAsset[] | []> => {
  debug(`queryDuplicateVisitorAssets() params is ${params}`);

  const duplicateVisitorAssets: APIt.VisitorAsset[] = [];

  try {
    const response = await API.graphql(graphqlOperation(listDuplicateVisitorAssets,
      {
        serial_num: params.serialNum,
        type: params.type,
        username: params.username,
      })) as GraphQLResult<APIt.ListDuplicateVisitorAssetsQuery>;
    debug(`queryDuplicateVisitorAssets() response is ${JSON.stringify(response)}`);
    if (response && response.data && response.data.listDuplicateVisitorAssets) {
      duplicateVisitorAssets.push(...(response.data.listDuplicateVisitorAssets as APIt.VisitorAsset[]));
    }
  } catch(error) {
    console.error(`getAccessLevelApproverGroups() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return duplicateVisitorAssets;
};
queryDuplicateVisitorAssets = auditDecorator('queryDuplicateVisitorAssets', queryDuplicateVisitorAssets);

export interface IQueryEmployeeActiveBadgesParams {
  emplId: string;
  region: string;
}

export let queryEmployeeActiveBadges = async (params: IQueryEmployeeActiveBadgesParams): Promise<APIt.EmployeeActiveBadges[]> => {
  debug(`queryEmployeeActiveBadges() params is ${JSON.stringify(params)}`);

  let employeeActiveBadges: APIt.EmployeeActiveBadges[] = [];

  try {
    const response = await API.graphql(graphqlOperation(getEmployeeActiveBadges,
      {
        input: {
          methodName: 'Welcome_GetEmployeeActiveBadges',
          params: params
        }
      })) as GraphQLResult<APIt.GetEmployeeActiveBadgesQuery>;
    debug(`queryEmployeeActiveBadges() response is ${JSON.stringify(response)}`);
    if (response.data && response.data.getEmployeeActiveBadges) {
      employeeActiveBadges = response.data.getEmployeeActiveBadges as APIt.EmployeeActiveBadges[];
    }
  } catch (e) {
    console.error(`queryEmployeeActiveBadges(): exception is ${JSON.stringify(e)}`);
    throw e;
  }

  debug(`queryEmployeeActiveBadges() employeeActiveBadges is ${JSON.stringify(employeeActiveBadges)}`);
  return(employeeActiveBadges);
};
queryEmployeeActiveBadges = auditDecorator('queryEmployeeActiveBadges', queryEmployeeActiveBadges);

export interface IQuerySupervisorReportsParams {
  emplId: string;
}

export let querySupervisorReports = async (params: IQuerySupervisorReportsParams): Promise<APIt.Report[]> => {
  debug(`querySupervisorReports() params is ${JSON.stringify(params)}`);

  let supervisorReports: APIt.Report[] = [];

  try {
    const response = await API.graphql(graphqlOperation(listSupervisorReports,
      {
        input: {
          methodName: 'Welcome_ListSupervisorReports',
          params: params
        }
      })) as GraphQLResult<APIt.ListSupervisorReportsQuery>;
    debug(`querySupervisorReports() response is ${JSON.stringify(response)}`);
    if (response.data && response.data.listSupervisorReports) {
      supervisorReports = response.data.listSupervisorReports as APIt.Report[];
    }
  } catch (e) {
    console.error(`querySupervisorReports(): exception is ${JSON.stringify(e)}`);
    throw e;
  }

  debug(`querySupervisorReports() supervisorReports is ${JSON.stringify(supervisorReports)}`);
  return(supervisorReports);
};
querySupervisorReports = auditDecorator('querySupervisorReports', querySupervisorReports);

export let queryLookupTypeValues = async (lookupTypeName: string): Promise<APIt.LookupTypeValue[]> => {
  debug(`queryLookupTypeValues() lookupTypeName is ${lookupTypeName}`);

  let lookupTypeValues: APIt.LookupTypeValue[] = [];
  let limit = 500;
  let offset = 0;

  while (true) {
    try {
      const response = await API.graphql(graphqlOperation(listLookupTypeValuesForTypeName, {
        input: {
	        limit: limit,
	        lookup_type_name: lookupTypeName,
	        offset: offset,
        }
      })) as GraphQLResult<APIt.ListLookupTypeValuesForTypeNameQuery>;
      debug(`queryLookupTypeValues() response is ${JSON.stringify(response)}`);
      if (response.data && response.data.listLookupTypeValuesForTypeName && response.data.listLookupTypeValuesForTypeName.length > 0) {
        lookupTypeValues.push(...(response.data.listLookupTypeValuesForTypeName as APIt.LookupTypeValue[]));
      } else {
        break;
      }
    } catch (e) {
      console.error(`queryLookupTypeValues() exception is ${JSON.stringify(e)}`);
      throw e;
    }
    offset += limit;
  }

  return(lookupTypeValues);
};
queryLookupTypeValues = auditDecorator('queryLookupTypeValues', queryLookupTypeValues);

export let queryLookupTypeValue = async (id: string): Promise<APIt.LookupTypeValue | null> => {
  debug(`queryLookupTypeValue() id is ${id}`);

  let lookupTypeValue: APIt.LookupTypeValue | null = null;

  try {
    const response = await API.graphql(graphqlOperation(getLookupTypeValue,
    {
      id
    },
    )) as GraphQLResult<APIt.GetLookupTypeValueQuery>;
    debug(`queryLookupTypeValue() response is ${JSON.stringify(response)}`);
    if (response?.data?.getLookupTypeValue) {
      lookupTypeValue = response.data.getLookupTypeValue as APIt.LookupTypeValue;
    }
  } catch(error) {
    debug(`queryLookupTypeValue() error is ${error} JSON: ${JSON.stringify(error)}`);
    console.error(error);
    throw error;
  }

  return lookupTypeValue;
};
queryLookupTypeValue = auditDecorator('queryLookupTypeValue', queryLookupTypeValue);

export let getLookupTypeValueId = async (typeName: string, value: string, ignoreCase: boolean = false): Promise<string | undefined> => {
  debug(`getLookupTypeValueId() typeName is ${typeName} value is ${value} ignoreCase is ${ignoreCase}`);

  try {
    const lookupTypeValues = await queryLookupTypeValues(typeName);
    const valueId = lookupTypeValues.find(v => {
      if (ignoreCase) {
        return v?.value?.toLowerCase() == value?.toLowerCase();
      }
      return v?.value == value;
    })!.id;
    if (!valueId) throw `Cannot find Lookup Type value for ${typeName} and ${value}`;
    debug(`getLookupTypeValueId() valueId is ${valueId}`);
    return valueId;
  } catch(error) {
    console.error(error);
    throw error;
  }
};
getLookupTypeValueId = auditDecorator('getLookupTypeValueId', getLookupTypeValueId);

export let getLookupTypeValues = async (typeName: string): Promise<APIt.LookupTypeValue[]> => {
  debug(`getLookupTypeValues() typeName is ${typeName}`);

  let lookupTypeValues: APIt.LookupTypeValue[] = [];

  try {
    const response = await API.graphql(graphqlOperation(getLookupTypeValuesForTypeName,
    {
      lookup_type_name: typeName
    },
    )) as GraphQLResult<APIt.GetLookupTypeValuesForTypeNameQuery>;
    debug(`getLookupTypeValues() response is ${JSON.stringify(response)}`);
    if (response?.data?.getLookupTypeValuesForTypeName && response.data.getLookupTypeValuesForTypeName.length > 0) {
      lookupTypeValues = response.data.getLookupTypeValuesForTypeName as APIt.LookupTypeValue[];
    }
  } catch(error) {
    console.error(error);
    throw error;
  }

  return lookupTypeValues;
};
getLookupTypeValues = auditDecorator('getLookupTypeValues', getLookupTypeValues);

export let queryVisitorAccessLevels = async (
  statuses: string[],
  updated_min: string | undefined = undefined,
  updated_max: string | undefined = undefined): Promise<APIt.VisitorAccessLevel[] | null> =>
{
  debug(`queryVisitorAccessLevels() status is ${status} updated_min is ${updated_min} updated_max is ${updated_max}`);

  let pendingVisitorAccessLevels: APIt.VisitorAccessLevel[] = [];

  for (const status of statuses) {
    const limit = 500;
    let offset = 0;

    while (true) {
      try {
        const response = await API.graphql(graphqlOperation(listVisitorAccessLevels,
          {
            limit: limit,
            offset: offset,
            status: status,
          })) as GraphQLResult<APIt.ListVisitorAccessLevelsQuery>;
        debug(`queryVisitorAccessLevels() response is ${JSON.stringify(response)}`);
        if (response.data && response.data.listVisitorAccessLevels) {
          pendingVisitorAccessLevels.push(...(response.data.listVisitorAccessLevels as APIt.VisitorAccessLevel[]));
        } else {
          break;
        }
      } catch (e) {
        console.error(`queryVisitorAccessLevels(): exception is ${JSON.stringify(e)}`);
        throw e;
      }
      offset += limit;
    }
  }
  return (pendingVisitorAccessLevels);
};
queryVisitorAccessLevels = auditDecorator('queryVisitorAccessLevels', queryVisitorAccessLevels);

export let queryVisitorAccessLevelsForApprover = async (
  approverId: string,
  statuses: string[],
  updated_min: string | undefined = undefined,
  updated_max: string | undefined = undefined): Promise<APIt.VisitorAccessLevel[] | null> =>
{
  debug(`queryVisitorAccessLevelsForApprover() approverId is ${approverId} status is ${status} updated_min is ${updated_min} updated_max is ${updated_max}`);

  if (!approverId) return null;

  let pendingVisitorAccessLevelsForApprover: APIt.VisitorAccessLevel[] = [];
  let approverSourceSystemId;

  debug(`queryVisitorAccessLevelsForApprover() after !status check`);

  try {
    approverSourceSystemId = await getLookupTypeValueId(LookupTypes.ApproverSourceSystem,'PACS');
    debug(`queryVisitorAccessLevelsForApprover() approverSourceSystemId is ${approverSourceSystemId}`);
  } catch(error) {
    console.error(`queryVisitorAccessLevelsForApprover() error is ${JSON.stringify(error)}`);
    throw error;
  }
  for (const status of statuses) {
    let limit = 500;
    let offset = 0;

    while (true) {
      try {
        const response = await API.graphql(graphqlOperation(listVisitorAccessLevelsForApprover,
          {
            approver_id: approverId,
            approver_source_system_id: approverSourceSystemId,
            limit: limit,
            offset: offset,
            status: status,
            updated_max: updated_max,
            updated_min: updated_min,
          })) as GraphQLResult<APIt.ListVisitorAccessLevelsForApproverQuery>;
        debug(`queryVisitorAccessLevelsForApprover() response is ${JSON.stringify(response)}`);
        if (response.data && response.data.listVisitorAccessLevelsForApprover) {
          pendingVisitorAccessLevelsForApprover.push(...(response.data.listVisitorAccessLevelsForApprover as APIt.VisitorAccessLevel[]));
        } else {
          break;
        }
      } catch (e) {
        console.error(`queryVisitorAccessLevelsForApprover(): exception is ${JSON.stringify(e)}`);
        throw e;
      }
      offset += limit;
    }
  } 
  return(pendingVisitorAccessLevelsForApprover);
};
queryVisitorAccessLevelsForApprover = auditDecorator('queryVisitorAccessLevelsForApprover', queryVisitorAccessLevelsForApprover);

export let queryVisitorAccessLevelsForVisitor = async (visitorId: string): Promise<APIt.VisitorAccessLevel[] | null> =>
{
  debug(`queryVisitorAccessLevelsForVisitor() visitorId is ${visitorId}`);

  if (!visitorId) return null;

  let visitorAccessLevelsForVisitor: APIt.VisitorAccessLevel[] = [];

  try {
    const response = await API.graphql(graphqlOperation(listVisitorAccessLevelsForVisitor,
      {
        visitor_id: visitorId,
      })) as GraphQLResult<APIt.ListVisitorAccessLevelsForVisitorQuery>;
    debug(`queryVisitorAccessLevelsForVisitor() response is ${JSON.stringify(response)}`);
    if (response.data && response.data.listVisitorAccessLevelsForVisitor) {
      visitorAccessLevelsForVisitor = response.data.listVisitorAccessLevelsForVisitor as APIt.VisitorAccessLevel[];
    }
  } catch (e) {
    console.error(`queryVisitorAccessLevelsForVisitor(): exception is ${JSON.stringify(e)}`);
    throw e;
  }

  return(visitorAccessLevelsForVisitor);
};
queryVisitorAccessLevelsForVisitor = auditDecorator('queryVisitorAccessLevelsForVisitor', queryVisitorAccessLevelsForVisitor);

export let queryVisitorRequestsForSite = async (
  siteId: string,
  statuses: ISelectOption[],
  visitMin: string | undefined = undefined,
  visitMax: string | undefined = undefined, visitorTypes: string[] = [VisitorTypes.EscortedWorker, VisitorTypes.Visitor, VisitorTypes.UnescortedVendor], includeEscorts: boolean = false): Promise<APIt.VisitorRequest[] | null> => {
  debug(`queryVisitorRequestsForSite() siteId is ${siteId} statuses is ${JSON.stringify(statuses)} visitMin is ${visitMin} visitMax is ${visitMax}`);

  if (!siteId) return null;

  let visitorRequestsForSite: APIt.VisitorRequest[] = await queryUnescortedVisitorRequestsForSite(siteId, statuses, visitMin, visitMax, visitorTypes, includeEscorts);
  visitorRequestsForSite.push(...(await queryEscortedVisitorRequestsForSite(siteId, statuses, visitMin, visitMax, visitorTypes, includeEscorts)));

  return (visitorRequestsForSite);
};
queryVisitorRequestsForSite = auditDecorator('queryVisitorRequestsForSite', queryVisitorRequestsForSite);

const queryUnescortedVisitorRequestsForSite = async (
  siteId: string,
  statuses: ISelectOption[] | undefined,
  visitMin: string | undefined = undefined,
  visitMax: string | undefined = undefined, visitorTypes: string[] = [VisitorTypes.EscortedWorker, VisitorTypes.Visitor, VisitorTypes.UnescortedVendor], includeEsorts: boolean = false): Promise<APIt.VisitorRequest[]> => {
  debug(`queryUnescortedVisitorRequestsForSite() siteId is ${siteId} statuses is ${JSON.stringify(statuses)} visitMin is ${visitMin} visitMax is ${visitMax}`);

  let
    visitorRequestsForSite: APIt.VisitorRequest[] = [],
    limit = 500;
  const unescortedVisitorTypes = visitorTypes.filter(v => v !== VisitorTypes.EscortedWorker && v !== VisitorTypes.Visitor).join(',');
  const statusesToQuery = statuses ? statuses.map(s => s.value).join(',') : '';
  let offset = 0;
  while (true) {
    try {
      const response = await API.graphql(graphqlOperation(listUnescortedVisitorRequestsForSiteCustom,
        {
          limit: limit,
          offset: offset,
          site_id: siteId,
          statuses: statusesToQuery,
          visit_max: visitMax,
          visit_min: visitMin,
          visitor_types: unescortedVisitorTypes,
          includeEscorts: includeEsorts
        })) as GraphQLResult<APIt.ListUnescortedVisitorRequestsForSiteQuery>;
      debug(`queryUnescortedVisitorRequestsForSite() response is ${JSON.stringify(response)}`);
      if (response.data && response.data.listUnescortedVisitorRequestsForSite && response.data.listUnescortedVisitorRequestsForSite.length > 0) {
        visitorRequestsForSite.push(...(response.data.listUnescortedVisitorRequestsForSite as APIt.VisitorRequest[]));
      } else {
        break;
      }
    } catch (e) {
      console.error(`queryUnescortedVisitorRequestsForSite(): exception is ${JSON.stringify(e)}`);
      throw e;
    }
    offset += limit;
  }

  debug(`queryUnescortedVisitorRequestsForSite() visitorRequestsForSite.length is ${visitorRequestsForSite.length}`);
  return (visitorRequestsForSite);
};

const queryEscortedVisitorRequestsForSite = async (
  siteId: string,
  statuses: ISelectOption[] | undefined,
  visitMin: string | undefined = undefined,
  visitMax: string | undefined = undefined, visitorTypes: string[] = [VisitorTypes.EscortedWorker, VisitorTypes.Visitor, VisitorTypes.UnescortedVendor], includeEscorts: boolean = false): Promise<APIt.VisitorRequest[]> => {
  debug(`queryEscortedVisitorRequestsForSite() siteId is ${siteId} statuses is ${JSON.stringify(statuses)} visitMin is ${visitMin} visitMax is ${visitMax}`);

  let
    visitorRequestsForSite: APIt.VisitorRequest[] = [],
    limit = 500;
  const statusesToQuery = statuses ? statuses.map(s => s.value).join(',') : '';
  let offset = 0;

  const escortedVisitorTypes = visitorTypes.filter(v => v === VisitorTypes.EscortedWorker || v === VisitorTypes.Visitor).join(',');

  offset = 0;
  while (true) {
    try {
      const response = await API.graphql(graphqlOperation(listEscortedVisitorRequestsForSiteCustom,
        {
          limit: limit,
          offset: offset,
          site_id: siteId,
          statuses: statusesToQuery,
          visit_max: visitMax,
          visit_min: visitMin,
          visitor_types: escortedVisitorTypes,
          includeEscorts: includeEscorts
        })) as GraphQLResult<APIt.ListEscortedVisitorRequestsForSiteQuery>;
      debug(`queryEscortedVisitorRequestsForSite() response is ${JSON.stringify(response)}`);
      if (response.data && response.data.listEscortedVisitorRequestsForSite && response.data.listEscortedVisitorRequestsForSite.length > 0) {
        visitorRequestsForSite.push(...(response.data.listEscortedVisitorRequestsForSite as APIt.VisitorRequest[]));
      } else {
        break;
      }
    } catch (e) {
      console.error(`queryEscortedVisitorRequestsForSite(): exception is ${JSON.stringify(e)}`);
      throw e;
    }
    offset += limit;
  }
  debug(`queryEscortedVisitorRequestsForSite() visitorRequestsForSite.length is ${visitorRequestsForSite.length}`);
  return (visitorRequestsForSite);
};

export const createVisitorAccessLevelApproval = async (visitorAccessLevelApprovalInput: APIt.CreateVisitorAccessLevelApprovalInput): Promise<APIt.VisitorAccessLevelApproval | null> => {
  debug(`createVisitorAccessLevelApproval() visitorAccessLevelApprovalInput is ${JSON.stringify(visitorAccessLevelApprovalInput)}`);

  let createdVisitorAccessLevelApproval: APIt.VisitorAccessLevelApproval | null = null;

  try {
    const response = await API.graphql(graphqlOperation(createVisitorAccessLevelApprovalMutation,
      {
        input: visitorAccessLevelApprovalInput
      })) as GraphQLResult<APIt.CreateVisitorAccessLevelApprovalMutation>;
    if (response && response.data && response.data.createVisitorAccessLevelApproval) {
      createdVisitorAccessLevelApproval = response.data.createVisitorAccessLevelApproval;
    }
  } catch(error) {
    console.error(`createVisitorAccessLevelApproval() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return createdVisitorAccessLevelApproval;
};

export let createEscort = async (escortInput: APIt.CreateRequestEscortInput): Promise<APIt.RequestEscort | null> => {
  debug(`createEscort() escortInput is ${JSON.stringify(escortInput)}`);

  let createdEscort: APIt.RequestEscort | null = null;

  try {
    const response = await API.graphql(graphqlOperation(createRequestEscortMutation,
      {
        input: escortInput
      })) as GraphQLResult<APIt.CreateRequestEscortMutation>;
    debug(`createEscort() response is ${JSON.stringify(response)}`);
    if (response && response.data && response.data.createRequestEscort) {
      createdEscort = response.data.createRequestEscort;
    }
  } catch (e) {
    console.error(`createEscort(): exception is ${JSON.stringify(e)}`);
    throw e;
  }

  return createdEscort;
};
createEscort = auditDecorator('createEscort', createEscort);

export let deleteEscort = async (deleteEscortInput: APIt.DeleteRequestEscortInput): Promise<APIt.RequestEscort | null> => {
  debug(`deleteEscort() deleteEscortInput is ${JSON.stringify(deleteEscortInput)}`);

  let deletedEscort: APIt.RequestEscort | null = null;

  try {
    const response = await API.graphql(graphqlOperation(deleteRequestEscortMutation,
      {
        input: deleteEscortInput
      })) as GraphQLResult<APIt.DeleteRequestEscortMutation>;
    debug(`deleteEscort() response is ${JSON.stringify(response)}`);
    if (response && response.data && response.data.deleteRequestEscort) {
      deletedEscort = response.data.deleteRequestEscort;
    }
  } catch (e) {
    console.error(`deleteEscort(): exception is ${JSON.stringify(e)}`);
    throw e;
  }

  return deletedEscort;
};
deleteEscort = auditDecorator('deleteEscort', deleteEscort);

export async function createUserAction(userActionInput: APIt.UserActionInput): Promise<APIt.UserAction | null> {
  debug(`createUserAction() userActionInput is ${JSON.stringify(userActionInput)}`);

  let newUserAction: APIt.UserAction | null = null;

  try {
    const response = await API.graphql(graphqlOperation(createUserActionMutation,
      {
        input: {
          ...userActionInput,
        }
      })) as GraphQLResult<APIt.CreateUserActionMutation>;
    if (response.data && response.data.createUserAction) {
      newUserAction = response.data.createUserAction as APIt.UserAction;
    }
  } catch(error) {
    console.error(`createUserAction() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return(newUserAction);
};

export const getVCESystemInfo = async (): Promise<any> => {
  const region = 'us-east-1';
  const functionUrl = 'https://kxoxrglrlqkih6fdoyqt5cuwze0csocc.lambda-url.us-east-1.on.aws/';
  debug('Lambda function URL:'+functionUrl);
  // Construct the full URL with the 'info' path
  const url = new URL('info', functionUrl);

  // Create the HTTP request
  const request = new HttpRequest({
    hostname: url.hostname,
    method: 'GET',
    headers: {
      'host': url.hostname,
    },
    path: url.pathname + url.search,
  });

  // Create a SignatureV4 object
  const signer = new SignatureV4({
    credentials: (await Auth.currentCredentials()),
    region: region,
    service: 'lambda',
    sha256: Sha256,
  });

  try {
    // Sign the request
    const signedRequest = await signer.sign(request);

    // Make the request
    const response = await fetch(url.toString(), {
      method: signedRequest.method,
      headers: signedRequest.headers,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response;
    debug('Lambda function URL response:'+JSON.stringify(data));
    return data;
  } catch (error) {
    console.error('Error calling Lambda function URL:', error);
    throw error;
  }
};
export type CandidateAssessmentParams = {
  candidateDocumentValue: string;
  candidateDocumentTypeId: string;
  positionId: string;
  scheduledDate: string;
  testLanguageId: string;
  firstName: string;
  lastName: string;
  gender: string;
  visitor_id: string;
}

export const getVisitorAssessmentsByUniqueVisitorId = async (uniqueVisitorId: string): Promise<APIt.VisitorAssessment[]> => {
  debug(`getVisitorAssessmentsByUniqueVisitorId() unique_visitor_id is ${uniqueVisitorId}`);
  let visitorAssessments: APIt.VisitorAssessment[] = [];
  try {
    const response = await API.graphql(graphqlOperation(listVisitorAssessmentsByUniqueVisitorId,
      {
        unique_visitor_id: uniqueVisitorId,
      })) as GraphQLResult<APIt.ListVisitorAssessmentsByUniqueVisitorIdQuery>;
    if (response.data && response.data.listVisitorAssessmentsByUniqueVisitorId) {
      visitorAssessments = response.data.listVisitorAssessmentsByUniqueVisitorId as APIt.VisitorAssessment[];
    }
  } catch (error) {
    debug(`getVisitorAssessmentsByUniqueVisitorId() error is ${JSON.stringify(error)}`);
    throw error;
  }
  return visitorAssessments;
}

export const createCandidateAssessment = async (candidateTestInput: CandidateAssessmentParams): Promise<APIt.VeritasCandidateTestResponse> => {
  const region = 'us-east-1';
  const functionUrl = 'https://kxoxrglrlqkih6fdoyqt5cuwze0csocc.lambda-url.us-east-1.on.aws/';
  debug('Lambda function URL:'+functionUrl);
  // Construct the full URL with the 'info' path
  const url = new URL(functionUrl);

  // Create the HTTP request
  const request = new HttpRequest({
    hostname: url.hostname,
    method: 'POST',
    headers: {
      'host': url.hostname,
      'Content-Type': 'application/json'
    },
    path: url.pathname + url.search,
    body: JSON.stringify({
      action: 'createTest',
      parameters: {
        candidateDocumentValue: candidateTestInput.candidateDocumentValue,
        candidateDocumentTypeId: candidateTestInput.candidateDocumentTypeId,
        positionId: candidateTestInput.positionId,
        scheduledDate: candidateTestInput.scheduledDate,
        testLanguageId: candidateTestInput.testLanguageId,
        firstName: candidateTestInput.firstName,
        lastName: candidateTestInput.lastName,
        gender: candidateTestInput.gender
      }
    })
  });

  // Create a SignatureV4 object
  const signer = new SignatureV4({
    credentials: (await Auth.currentCredentials()),
    region: region,
    service: 'lambda',
    sha256: Sha256,
  });

  try {
    // Sign the request
    const signedRequest = await signer.sign(request);

    // Make the request
    const response = await fetch(url.toString(), {
      method: signedRequest.method,
      headers: signedRequest.headers,
      body: request.body  
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const responseData = await response.json();
    if (!responseData.candidateTestResult) {
      throw new Error('No candidateTestResult in response');
    };
    const data = responseData.candidateTestResult as APIt.VeritasCandidateTestResponse;
    return  data;
    
  } catch (error) {
    console.error('Error calling Lambda function URL:', error);
    throw error;
  }
};
export let querySites = async (filterAirSites: boolean): Promise<APIt.Site[]> => {
  debug(`querySites() filterAirSites is ${filterAirSites}`);

  let sites: APIt.Site[];
  let airSites: APIt.Site[] = [];

  try {
    const response = await API.graphql(graphqlOperation(listSites,
      {
        input: {
          methodName: 'Welcome_GetSiteList_V2'
        }
      })) as GraphQLResult<APIt.ListSitesQuery>;
    debug(`querySites() response is ${JSON.stringify(response)}`);
    if (response.data && response.data.listSites) {
      const map = new Map<string, APIt.Site>();
      for(const site of response.data.listSites){
        if(!site)
          continue;
        if(site.CountryName){
          map.set(site.SiteCode, site);
        }
      }
      sites = Array.from(map.values());
      if (filterAirSites) {
        airSites = await queryAirSites();
        debug(`querySites() airSites is ${JSON.stringify(airSites)}`);
        sites = sites.filter(s => !airSites.find(as => as.SiteCode == s.SiteCode));
        }
      debug(`querySites() sites.length is ${sites.length}`);
      return(sites);
    }
    throw('Unable to locate sites');
  } catch(error) {
    console.error(`querySites() error is ${JSON.stringify(error)}`);
    throw error;
  }

};
querySites = auditDecorator('querySites', querySites);

export const queryLookupTypes = async (): Promise<APIt.LookupType[]> => {
  debug('queryLookupTypes()');

  let lookupTypes: APIt.LookupType[] = [];

  try {
    const response = await API.graphql(graphqlOperation(listLookupTypes, {})) as GraphQLResult<APIt.ListLookupTypesQuery>;
    if (response.data && response.data.listLookupTypes) {
      lookupTypes = response.data.listLookupTypes as APIt.LookupType[];
    }
  } catch (e) {
    console.error(`queryLookupTypes() exception is ${JSON.stringify(e)}`);
    throw e;
  }

  return(lookupTypes);
};

export let queryAccessLevelsForSite = async (siteCode: string | undefined, includeManageVisitors: boolean = false): Promise<APIt.AccessLevelForSite[]> => {
  debug(`queryAccessLevelsForSite() siteCode is ${siteCode}`);

  let accessLevelsForSite: APIt.AccessLevelForSite[] = [];

  if (!siteCode) return accessLevelsForSite;

  try {
    const response = await API.graphql(graphqlOperation(listAccessLevelsForSite,
      {
        input: {
          methodName: 'Welcome_GetAccessLevelsForSite',
          params: {
            siteCode: siteCode
          }
        }
      })) as GraphQLResult<APIt.ListAccessLevelsForSiteQuery>;
    debug(`queryAccessLevelsForSite() response is ${JSON.stringify(response)}`);
    if (response.data && response.data.listAccessLevelsForSite) {
      accessLevelsForSite = response.data.listAccessLevelsForSite as APIt.AccessLevelForSite[];
      if (includeManageVisitors) {
        const manageVisitorsAccessLevelName = `${siteCode}-MANAGE VISITORS`;
        accessLevelsForSite.push({
          __typename: 'AccessLevelForSite',
          AccessLevelID: 0,
          AccessLevelName: manageVisitorsAccessLevelName,
        });
      }
      return(accessLevelsForSite);
    }
    throw(`Unable to locate access levels for ${siteCode}.`);
  } catch(error) {
    console.error(`queryAccessLevelsForSite() error is ${error} JSON: ${JSON.stringify(error)}`);
    throw error;
  }

};
queryAccessLevelsForSite = auditDecorator('queryAccessLevelsForSite', queryAccessLevelsForSite);

export let queryAirSites = async () => {
  debug(`queryAirSites()`);

  let sites: APIt.Site[];

  try {
    const response = await API.graphql(graphqlOperation(listSites,
      {
        input: {
          methodName: "Welcome_GetAirSiteList_V2"
        }
      })) as GraphQLResult<APIt.ListSitesQuery>;
    debug(`queryAirSites() response is ${JSON.stringify(response)}`);
    if (response.data && response.data.listSites) {
      sites = response.data.listSites as APIt.Site[];
      return(sites);
    }
    throw('Unable to locate sites');
  } catch (e) {
    console.error(`queryAirSites(): exception is ${JSON.stringify(e)}`);
    throw e;
  }

};
queryAirSites = auditDecorator('queryAirSites', queryAirSites);

export let queryApproverGroups = async (): Promise<APIt.ApproverGroup[]> => {
  debug('queryApproverGroups()');

  let approverGroups: APIt.ApproverGroup[] = [];
  let limit = 500;
  let offset = 0;

  while (true) {
    try {
      const response = await API.graphql(graphqlOperation(listApproverGroups, {
        input: {
	        limit: limit,
	        offset: offset,
        }
      })) as GraphQLResult<APIt.ListApproverGroupsQuery>;
      debug(`queryApproverGroups() response is ${JSON.stringify(response)}`);
      if (response.data && response.data.listApproverGroups && response.data.listApproverGroups.length > 0) {
        approverGroups.push(...(response.data.listApproverGroups as APIt.ApproverGroup[]));
      } else {
        break;
      }
    } catch (e) {
      console.error(`queryApproverGroups() exception is ${JSON.stringify(e)}`);
      throw e;
    }
    offset += limit;
  }

  return(approverGroups);
};
queryApproverGroups = auditDecorator('queryApproverGroups', queryApproverGroups);

export let queryEmployeeDetails = async (login: string): Promise<APIt.EmployeeDetails | undefined> => {
  debug(`queryEmployeeDetails() login is ${login}`);

  let employeeDetails: APIt.EmployeeDetails | undefined = undefined;

  try {
    const response = await API.graphql(graphqlOperation(getEmployeeDetailsForLogin,
      {
        input: {
          methodName: 'Welcome_GetEmployeeDetailsForLogin',
          params: {
            login: login
          }
        }
      })) as GraphQLResult<APIt.GetEmployeeDetailsForLoginQuery>;
    debug(`queryEmployeeDetails() response is ${JSON.stringify(response)}`);
    if (response.data && response.data.getEmployeeDetailsForLogin) {
      employeeDetails = response.data.getEmployeeDetailsForLogin as APIt.EmployeeDetails;
      return(employeeDetails);
    }
    throw(`Unable to locate employee details for ${login}.`);
  } catch (e) {
    // this will occur for the non onboarded visitors
    debug(`queryEmployeeDetails(): exception is ${JSON.stringify(e)}`);
    throw e;
  }
};
queryEmployeeDetails = auditDecorator('queryEmployeeDetails', queryEmployeeDetails);

export let queryEmployees = async (login: string, firstName: string, lastName: string, emplId: string): Promise<APIt.EmployeeDetails[]> => {
  debug(`queryEmployees() login is ${login} firstName is ${firstName} lastName is ${lastName} emplId is ${emplId}`);

  let employees: APIt.EmployeeDetails[] = [];

  try {
    const response = await API.graphql(graphqlOperation(listEmployees,
      {
        input: {
          methodName: 'Welcome_ListEmployees',
          params: {
            login: login,
            firstName: firstName,
            lastName: lastName,
            emplId: emplId,
          }
        }
      })) as GraphQLResult<APIt.ListEmployeesQuery>;
    debug(`queryEmployees() response is ${JSON.stringify(response)}`);
    if (response.data && response.data.listEmployees) {
      employees = response.data.listEmployees as APIt.EmployeeDetails[];
    }
    return(employees);
  } catch (e) {
    // this will occur for the non onboarded visitors
    debug(`queryEmployees(): exception is ${JSON.stringify(e)}`);
    throw e;
  }
};
queryEmployees = auditDecorator('queryEmployees', queryEmployees);

export let queryLookupTypeValueForTypeAndValue = async (lookupTypeName: string, value: string): Promise<APIt.LookupTypeValue> => {
  debug(`queryLookupTypeValueForTypeAndValue() lookupTypeName is ${lookupTypeName} value is ${value}`);

  let lookupTypeValue: APIt.LookupTypeValue;

  try {
    const response = await API.graphql(graphqlOperation(getLookupTypeValueForTypeNameAndValue,
      {
        lookup_type_name: lookupTypeName,
        value: value,
      })) as GraphQLResult<APIt.GetLookupTypeValueForTypeNameAndValueQuery>;
    debug(`queryLookupTypeValueForTypeAndValue() response is ${JSON.stringify(response)}`);
    if (response.data && response.data.getLookupTypeValueForTypeNameAndValue) {
      lookupTypeValue = response.data.getLookupTypeValueForTypeNameAndValue as APIt.LookupTypeValue;
      return(lookupTypeValue);
    }
    throw('Unable to find lookup type value');
  } catch(error) {
    console.error(`queryLookupTypeValueForTypeAndValue() error is ${JSON.stringify(error)}`);
    throw error;
  }

};
queryLookupTypeValueForTypeAndValue = auditDecorator('queryLookupTypeValueForTypeAndValue', queryLookupTypeValueForTypeAndValue);

export let queryLookupTypeValueForTypeAndDescription = async (lookupTypeName: string, description: string): Promise<APIt.LookupTypeValue> => {
  debug(`queryLookupTypeValueForTypeAndDescription() lookupTypeName is ${lookupTypeName} description is ${description}`);

  let lookupTypeValues: APIt.LookupTypeValue[] = [];

  try {
    const lookupTypeValues = await getLookupTypeValues(lookupTypeName);
    debug(`queryLookupTypeValueForTypeAndDescription() lookupTypeValues is ${JSON.stringify(lookupTypeValues)}`);
    if (lookupTypeValues) {
      const lookupTypeValue = lookupTypeValues.find(ltv => ltv.description == description);
      if (lookupTypeValue) return(lookupTypeValue);
    }
    throw('Unable to find lookup type value');
  } catch(error) {
    console.error(`queryLookupTypeValueForTypeAndDescription() error is ${JSON.stringify(error)}`);
    console.error(error);
    throw error;
  }

};
queryLookupTypeValueForTypeAndDescription = auditDecorator('queryLookupTypeValueForTypeAndDescription', queryLookupTypeValueForTypeAndDescription);

export let queryVisitorAssets = async (
  visitorId: string | undefined = undefined,
  siteId: string | undefined = undefined,
  siteSourceSystemId: string | undefined = undefined): Promise<APIt.VisitorAsset[] | null> =>
{
  debug(`queryVisitorAssets() visitorId is ${visitorId} siteId is ${siteId} siteSourceSystemId is ${siteSourceSystemId}`);

  let visitorAssets: APIt.VisitorAsset[] = [];

  try {
    const response = await API.graphql(graphqlOperation(listVisitorAssets,
      {
        visitor_id: visitorId,
        siteId: siteId,
        site_source_system_id: siteSourceSystemId,
      })) as GraphQLResult<APIt.ListVisitorAssetsQuery>;
    if (response.data && response.data.listVisitorAssets) {
      visitorAssets = response.data.listVisitorAssets as APIt.VisitorAsset[];
    }
  } catch (e) {
    console.error(`queryVisitorAssets() exception is ${JSON.stringify(e)}`);
    throw e;
  }

  debug(`queryVisitorAssets() visitorAssets is ${JSON.stringify(visitorAssets)}`);

  return(visitorAssets);
};
queryVisitorAssets = auditDecorator('queryVisitorAssets', queryVisitorAssets);

export let queryVisitorAssetsForApprover = async (
  approverId: string,
  status: string | undefined = undefined,
  updated_min: string | undefined = undefined,
  updated_max: string | undefined = undefined): Promise<APIt.VisitorAsset[] | null> =>
{
  debug(`queryVisitorAssetsForApprover() approverId is ${approverId} status is ${status} updated_min is ${updated_min} updated_max is ${updated_max}`);

  if (!approverId) return null;

  let pendingVisitorAssetsForApprover: APIt.VisitorAsset[] = [];
  let approverSourceSystemId;

  try {
    approverSourceSystemId = await getLookupTypeValueId(LookupTypes.ApproverSourceSystem,'PACS');
    debug(`queryVisitorAssetsForApprover() approverSourceSystemId is ${approverSourceSystemId}`);
  } catch(error) {
    console.error(`queryVisitorAssetsForApprover() error is ${JSON.stringify(error)}`);
    throw error;
  }

  let limit = 500;
  let offset = 0;

  while (true) {
    try {
      const response = await API.graphql(graphqlOperation(listVisitorAssetsForApproverV2,
        {
          approver_id: approverId,
          approver_source_system_id: approverSourceSystemId,
	        limit,
	        offset,
          status: status,
          updated_max: updated_max,
          updated_min: updated_min,
        })) as GraphQLResult<APIt.ListVisitorAssetsForApproverV2Query>;
      debug(`queryVisitorAssetsForApprover() response is ${JSON.stringify(response)}`);
      if (response.data && response.data.listVisitorAssetsForApproverV2) {
        pendingVisitorAssetsForApprover.push(...response.data.listVisitorAssetsForApproverV2 as APIt.VisitorAsset[]);
      } else {
        break;
      }
    } catch (e) {
      console.error(`queryVisitorAssetsForApprover(): exception is ${JSON.stringify(e)}`);
      throw e;
    }
    offset += limit;
  }

  return(pendingVisitorAssetsForApprover);
};
queryVisitorAssetsForApprover = auditDecorator('queryVisitorAssetsForApprover', queryVisitorAssetsForApprover);

export let queryVisitorAssetsForStatus = async (
  status: string): Promise<APIt.VisitorAsset[] | null> => {
  debug(`queryVisitorAssetsForStatus() status is ${status}`);

  let visitorAssets: APIt.VisitorAsset[] = [];
  let limit = 500;
  let offset = 0;

  while (true) {
    try {
      const response = await API.graphql(graphqlOperation(listVisitorAssetsForStatus,
        {
          limit: limit,
          offset: offset,
          status: status,
        })) as GraphQLResult<APIt.ListVisitorAssetsForStatusQuery>;
      debug(`queryVisitorAssetsForStatus() response is ${JSON.stringify(response)}`);
      if (response.data && response.data.listVisitorAssetsForStatus) {
        visitorAssets.push(...(response.data.listVisitorAssetsForStatus as APIt.VisitorAsset[]));
      } else {
        break;
      }
    } catch (e) {
      console.error(`queryVisitorAssetsForStatus(): exception is ${JSON.stringify(e)}`);
      throw e;
    }
    offset += limit;
  }

  return (visitorAssets);
};
queryVisitorAssetsForStatus = auditDecorator('queryVisitorAssetsForStatus', queryVisitorAssetsForStatus);

export let queryVisitorAssetTypes = async (): Promise<APIt.LookupTypeValue[]> => {
  debug('queryVisitorAssetTypes()');

  let visitorAssetTypes: APIt.LookupTypeValue[] = [];

  try {
    const response = await API.graphql(graphqlOperation(listLookupTypeValuesForTypeName,
      {
        input: {
	        limit: 1000,
	        lookup_type_name: LookupTypes.AssetType,
	        offset: 0,
        }
      })) as GraphQLResult<APIt.ListLookupTypeValuesForTypeNameQuery>;
    if (response.data && response.data.listLookupTypeValuesForTypeName) {
      visitorAssetTypes = response.data.listLookupTypeValuesForTypeName as APIt.LookupTypeValue[];
    }
  } catch (e) {
    console.error(`queryVisitorAssetTypes() exception is ${JSON.stringify(e)}`);
    throw e;
  }

  return(visitorAssetTypes);
};
queryVisitorAssetTypes = auditDecorator('queryVisitorAssetTypes', queryVisitorAssetTypes);

export let queryVisitorAccessLevelsByUniqueVisitor = async (
 unique_visitor_id: string, start_date: string, end_date: string): Promise<APIt.VisitorAccessLevel[] | null> =>
{
  debug(`queryVisitorAccessLevelsByUniqueVisitor() unique_visitor_id is ${unique_visitor_id} start_date is ${start_date} end_date is ${end_date}`);

  let visitorAccessLevels: APIt.VisitorAccessLevel[] = [];

      try {
        for(const status of [ApprovalStatus.Activated, ApprovalStatus.Approved, ApprovalStatus.Deactivated, ApprovalStatus.PendingApproval, ApprovalStatus.PendingPOCApproval, ApprovalStatus.Submitted, ApprovalStatus.PendingAssessment]){
          const response = await API.graphql(graphqlOperation(listVisitorAccessLevelsByUniqueVisitorId,
            {
              unique_visitor_id,
              start_date, 
              end_date,
              status
            })) as GraphQLResult<APIt.ListVisitorAccessLevelsByUniqueVisitorIdQuery>;
          debug(`queryVisitorAccessLevelsByUniqueVisitor() response is ${JSON.stringify(response)}`);
          if (response.data && response.data.listVisitorAccessLevelsByUniqueVisitorId) {
            visitorAccessLevels.push(...(response.data.listVisitorAccessLevelsByUniqueVisitorId as APIt.VisitorAccessLevel[]));
          } 
        }
       
      } catch (e) {
        console.error(`queryVisitorAccessLevelsByUniqueVisitor(): exception is ${JSON.stringify(e)}`);
        throw e;
      }

  return (visitorAccessLevels);
};
queryVisitorAccessLevelsByUniqueVisitor = auditDecorator('queryVisitorAccessLevelsByUniqueVisitor', queryVisitorAccessLevelsByUniqueVisitor);

export const getApprovers = async (approverGroup: string): Promise<string[]> => {
  debug(`getApprovers() approverGroup is ${JSON.stringify(approverGroup)}`);

  type TlistApproverGroupMembersResponse = { statusCode: number, data: {result: string}[] };

  const input: any = {
    path: SIGInfraAPIv2Methods.GetADGroupsMembersIncludingNesting,
    params: [
      { group: approverGroup },
    ]
  };
  debug(`getApprovers() input is ${JSON.stringify(input)}`);

  let approvers: string[] = [];

  try {
    const response = await API.graphql(graphqlOperation(listApproverGroupMembers,
      {
        input: JSON.stringify(input)
      })) as GraphQLResult<APIt.ListApproverGroupMembersQuery>;
    if (response && response.data && response.data.listApproverGroupMembers) {
      const listApproverGroupMembersResponse: TlistApproverGroupMembersResponse = JSON.parse(response.data.listApproverGroupMembers) as TlistApproverGroupMembersResponse;
      for (let dataElement of listApproverGroupMembersResponse.data) {
        if (dataElement && dataElement.result) {
          const cn = dataElement.result.split(',').find((value: string) => value.slice(0,2) == 'CN')?.split('=')[1];
          if (cn && !dataElement.result.split(',').find((value: string) => value == 'OU=Groups')) approvers.push(cn);
        }
      }
    }
  } catch(error) {
    console.error(`getApprovers() error is ${JSON.stringify(error)}`);
    throw error;
  }

  debug(`getApprovers() approvers is ${JSON.stringify(approvers)}`);
  return approvers;
};

export let queryAccessLevelApproverGroups = async (accessLevelName: string | undefined): Promise<APIt.AccessLevelApproverGroup[] | null> => {
  debug(`queryAccessLevelApproverGroups() accessLevelName is ${accessLevelName}`);

  let accessLevelApproverGroups: APIt.AccessLevelApproverGroup[] = [];

  if (!accessLevelName) return accessLevelApproverGroups;

  try {
    const response = await API.graphql(graphqlOperation(listAccessLevelApproverGroups,
      {
        accessLevelName: accessLevelName
      })) as GraphQLResult<APIt.ListAccessLevelApproverGroupsQuery>;
    debug(`queryAccessLevelApproverGroups() response is ${JSON.stringify(response)}`);
    if (response.data && response.data.listAccessLevelApproverGroups) {
      accessLevelApproverGroups = response.data.listAccessLevelApproverGroups as APIt.AccessLevelApproverGroup[];
    }
  } catch(error) {
    console.error(`queryAccessLevelApproverGroups() error is ${JSON.stringify(error)}`);
    throw error;
  }

  debug(`queryAccessLevelApproverGroups() accessLevelApproverGroups is ${JSON.stringify(accessLevelApproverGroups)}`);
  return(accessLevelApproverGroups);
};
queryAccessLevelApproverGroups = auditDecorator('queryAccessLevelApproverGroups', queryAccessLevelApproverGroups);

export let userCanManageVisitorsForSite = async (username: string, sitecode: string): Promise<boolean> => {
  debug(`userCanManageVisitorsForSite() username is ${username} sitecode is ${sitecode}`);

  if (!username || !sitecode) return false;

  const manageVisitorsApproverGroupsForSite = await queryAccessLevelApproverGroups(`${sitecode}-MANAGE VISITORS`);
  debug(`userCanManageVisitorsForSite() manageVisitorsApproverGroupsForSite is ${JSON.stringify(manageVisitorsApproverGroupsForSite)}`);

  if (!manageVisitorsApproverGroupsForSite) return false;

  let userCanManageVisitorsForSite: boolean = false;
  
  for (let manageVisitorsApproverGroupForSite of manageVisitorsApproverGroupsForSite) {
    const approvers = await getApprovers(manageVisitorsApproverGroupForSite.approver_group);
    debug(`userCanManageVisitorsForSite() approvers is ${JSON.stringify(approvers)}`);
    if (approvers.includes(username)) return true;
  }

  debug(`userCanManageVisitorsForSite() returning userCanManageVisitorsForSite is ${userCanManageVisitorsForSite}`);
  return(userCanManageVisitorsForSite);
};
userCanManageVisitorsForSite = auditDecorator('userCanManageVisitorsForSite', userCanManageVisitorsForSite);

export async function throttle(
  apiFunc: () => Promise<any>,
  delayMs: number
): Promise<any> {
  const startTime = Date.now();
  const result = await apiFunc();
  const endTime = Date.now();

  const elapsedTime = endTime - startTime;
  const remainingDelay = delayMs - elapsedTime;

  if (remainingDelay > 0) {
    await new Promise(resolve => setTimeout(resolve, remainingDelay));
  }

  return result;
}

export const sqlEscapeString = (str: string) => {
  // escape charaters in the given string for postgresql
  let newString = str;
  newString = newString.replace(/'/g, "''");
  newString = newString.replace(/"/g, '\"');
  newString = newString.replace(/\n/g, ' ');
  return newString;
};

const getFirstDayOfCurrentMonth = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

const getLastDayOfCurrentMonth = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

const getFirstDayOfPriorMonth = () => {
  const date = new Date();
  date.setMonth(date.getMonth()-1);
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

const getLastDayOfPriorMonth = () => {
  const date = new Date();
  date.setMonth(date.getMonth()-1);
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

const getDateFromDays = (days: number): string => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return `${date.getFullYear().toString()}-${(date.getMonth()+1).toString().padStart(2,'0')}-${date.getDate().toString().padStart(2,'0')}`;
};

/**
 * return a non localized date
 * dateString format is 'YYYY-MM-DD'
*/
function getUTCDate(dateString: string): Date {
  debug(`getUTCDate() dateString is ${dateString}`);
  const [year, month, day] = dateString.split('-');
  debug(`getUTCDate() year is ${year} month is ${month} day is ${day}`);
  const utcDate = Date.UTC(parseInt(year), parseInt(month) - 1, parseInt(day));
  debug(`getUTCDate() utcDate is ${utcDate}`);
  return new Date(utcDate);
}

export const isFromDateValid = (date: Date, hoursExtFromDate: number) => {
  debug(`isFromDateValid() date is ${date} hoursExtFromDate is ${hoursExtFromDate}`);
  if (!date || isNaN(date.getTime())) return false;
  const currentDate = Date.now();
  const offsetHours = new Date(currentDate).getTimezoneOffset()/60;
  const earliestDate = subHours(currentDate, hoursExtFromDate + offsetHours * -1);
  debug(`isFromDateValid() date is ${date} currentDate is ${currentDate} offsetHours is ${offsetHours} earliest`);
  return(isAfter(date, earliestDate));
};

export const isThroughDateValid = (fromDate: Date, throughDate: Date, vendorDayPassMaxDays: number | null = null): boolean => {
  if (!fromDate || isNaN(fromDate.getTime()) || !throughDate || isNaN(throughDate.getTime())) return false;
  let isValid = true;
  debug(`isThroughDateValid() fromDate is ${fromDate} throughDate is ${throughDate} vendorDayPassMaxDays is ${vendorDayPassMaxDays}`);
  if (throughDate.getTime() < fromDate.getTime()) isValid = false;
  if (vendorDayPassMaxDays) {
    if ((throughDate.getTime()) - (fromDate.getTime()) > (1000*60*60*24*vendorDayPassMaxDays)) isValid = false;
  }
  return isValid;
};

export const DateFunctions = {
  getDateFromDays,
  getFirstDayOfCurrentMonth,
  getFirstDayOfPriorMonth,
  getLastDayOfCurrentMonth,
  getLastDayOfPriorMonth,
  getUTCDate,
}